import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import '../newRoadmap.css';
let logo = 'https://smassets.pages.dev/assets/6.jpg';
let roadmapUrl = 'https://smassets.pages.dev/assets/roadmap.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Utility extends Component {




    render() {
        return (

            <div class="boxWrap2Rm">


                <div class="storyH"><img class="middleR" src={roadmapUrl}/></div>

                <div class="wrapper">
                    <div class="timeline">
                        <dl class="timeline--entry">
                            <dt class="timeline--entry__title">0% | Raising Awareness</dt>
                            <dd class="timeline--entry__detail">Build out social channels (Twitter, Discord, Instagram, Website, Opensea).</dd>
                            <dd class="timeline--entry__detail">Set up like-minded social collaborations to scale following organically</dd>
                            <dd class="timeline--entry__detail">Begin development and audit of smart contracts to ensure smooth mint.</dd>
                            <dd class="timeline--entry__detail">Program percentage of mint and secondary sales towards community wallet.</dd>
                        </dl>
                        <dl class="timeline--entry">
                            <dt class="timeline--entry__title">33% | Soul-searching</dt>
                            <dd class="timeline--entry__detail">Host AMAs, speaker events, and meditation sessions on Twitter Spaces.</dd>
                            <dd class="timeline--entry__detail">Inspire continued growth through partnerships and collaborations</dd>
                            <dd class="timeline--entry__detail">Host community giveaways for NFTs, exclusive roles, and other perks</dd>
                        </dl>
                        <dl class="timeline--entry">
                            <dt class="timeline--entry__title">66% | The Truth</dt>
                            <dd class="timeline--entry__detail">Install active community members as Weekend Warriors (Ambassadors of the Project)</dd>
                            <dd class="timeline--entry__detail">Develop alignments with mental health organizations</dd>
                            <dd class="timeline--entry__detail">Invest in spiritual resources for community members</dd>
                            <dd class="timeline--entry__detail">Release a merchandise store where holders can buy custom clothing featuring their favorite NFTs</dd>
                        </dl>
                        <dl class="timeline--entry">
                            <dt class="timeline--entry__title">99% | Embracing the Light</dt>
                            <dd class="timeline--entry__detail">Donate to nonprofits organizations benefiting underprivileged communities</dd>
                            <dd class="timeline--entry__detail">Host weekly meditation and learning sessions over Discord</dd>
                            <dd class="timeline--entry__detail">Appoint teams of supporters to spread the word full-time</dd>
                            <dd class="timeline--entry__detail">Design and lead weekly activities (mysteries, lore nights) with real-life prizes</dd>
                        </dl>
                        <dl class="timeline--entry">
                            <dt class="timeline--entry__title">100% | The Answer is found</dt>
                            <dd class="timeline--entry__detail">Conclude the story of the mysterious fog</dd>
                            <dd class="timeline--entry__detail">Distribute prizes to most active participants and contributors who worked on the mysterious fog mystery case</dd>
                            <dd class="timeline--entry__detail">Collaborate with holders to prepare for next rendition of the roadmap</dd>
                        </dl>

                    </div>
                </div>
                <div class="boxWrap2Footer">


                    <div class="footer">
                        <div><img class="logoF" src={logo} /></div>
        
                        <div class="copyright">Copyright © 2022 SM. All Rights Reserved</div>
                    </div>

                </div>
            </div>
        )
    }
}

export default Utility;

