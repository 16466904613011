import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import Carousel from '../components/carousel';

let ghost = 'https://smassets.pages.dev/assets/sticky.png';
let storyUrl = 'https://smassets.pages.dev/assets/story.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Story extends Component {




    render() {
        return (

            <div class="boxWrap2" >

                <div class="storyH"><img class="middleS" src={storyUrl}/></div>

                <div class="about" >
                    <div class="storyCon">
                        <p class="storyCon2">Thriving as the coolest creatures on the block, the Saturdays are a neighborhood of suave, laidback
                            monsters who love getting together to solve the mysteries of their community.
                        </p>
                        <p class="storyCon2">Nicknamed after their favorite day, the Saturday Monsters aptly believe in the concept of ‘work hard, play hard’ as they transition from their busy day jobs to unraveling the dark secrets of corrupt, top-secret treasure maps and hidden civilizations that span underneath the mountain cities that they lived in.</p>
                        <p class="storyCon2">With a handwritten tip received from Shanks, the neighborhood’s longest living spy, the Saturday Monsters were informed of an upcoming attack in their city’s famous Jazz Club, “the Sinatra '' during the long weekend. First equipping themselves with an arsenal of goo-guns and goonades, the Saturday Monsters agreed on sending ten of their best members to investigate the situation. Their plan was to blend into the Sinatra Jazz Club as customers at the bar, and cover each angle so that no member would be caught by surprise in case the attacker began escalating the situation.</p>
                        <p class="storyCon2">After a long day of anticipation, the Jazz Club opened after midnight and welcomed the most lavish monsters in its wake. As the brave Saturday Monsters sat down in their planned, scattered formation, they noticed that the bartender was behaving shifty and odd. A black backpack sat by his bar, with tape covering its zippers and wrapping it tightly. The bartender had brought a bomb to the bar.</p>
                        <p class="storyCon2">With steady hands and sneakiness, one monster began to chat up the bartender while the others moved the backpack in his distraction outside into a dumpster. Afterwards, they defused the bomb and threw it inside the dumpster for safekeeping. Meanwhile, inside the Sinatra, the remaining monsters slowly approached the bartender and handcuffed him to the counter. As the evil bartender groaned with annoyance, the Saturday Monsters celebrated how they just saved the lives of many through hard work and a bit of curiosity.</p>
                        <p class="storyCon2">As the Saturday Monsters turned the bartender to the police, they sat behind the bar themselves and started making drinks for each other. Now that this weekend’s mystery was solved, they prepared for their work week ahead and waited for next Saturday, where they could experience the thrill and excitement of danger once again.</p>
                   
                    </div>
                    <img class="stickyGhost" src={ghost} />

                </div>
                <Carousel />

            </div>
        )
    }
}

export default Story;

