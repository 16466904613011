import React, { Component, useEffect } from 'react';
import "aos/dist/aos.css";
import Aos from 'aos';
import '../carousel2.css';
let Pic1 = 'https://smassets.pages.dev/assets/4.jpg'; 
let Pic2 = 'https://smassets.pages.dev/assets/13.jpg'; 
let Pic3 = 'https://smassets.pages.dev/assets/2.jpg'; 

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Carousel2 extends Component {




    render() {
        return (
            <div class="cMain">
                <div class="carousel__container">
                    <div class="carousel__item">
                        <img src={Pic1} class="carousel__image"/>
                    </div>                    
                    <div class="carousel__item">
                        <img src={Pic2} class="carousel__image"/>
                    </div>
                    <div class="carousel__item">
                        <img src={Pic3} class="carousel__image"/>
                    </div>
                   
                </div>
            </div>
        )
    }
}

export default Carousel2;

