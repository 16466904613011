import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';

let openseaUrl = 'https://smassets.pages.dev/assets/openseaTitle.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Opensea extends Component {

    render() {
        return (

            <div class="boxWrap2Opensea" >

                <div  class="storyH"><img class="middleO" src={openseaUrl}/></div>

                <div class="about" >
                    <div class="storyConOs">
                        <p class="storyCon2">Thriving as the coolest creatures on the block, the Saturday Monsters are a neighborhood of 5,000 suave, laidback explorers who love getting together to solve the mysteries of their community. Each equipped with their unique strengths, the Saturday Monsters find happiness in discovering new secrets to areas to explore, braving challenging obstacles, and finding answers in the dark mysteries of their world every weekend.
                        </p>
  
                        <p class="storyCon2">By minting your own Saturday Monster NFT, you can join their squad in their weekly weekend adventures, and uncover new ways to see the world with charisma.</p>

                    </div>
                    <div class="animation-wrapper">
                    <div class="water">
                        <ul class="waves">
                            <li class="wave-one"></li>
                            <li class="wave-two"></li>
                            <li class="wave-three"></li>
                            <li class="wave-four"></li>
                        </ul>
                    </div>
                    <div class="boat"></div>
                     <div class="cloud2"></div>
                </div>
                </div>


            </div>
        )
    }
}

export default Opensea;

