import React, { Component } from 'react';
import '../carousel.css';
let d1 = 'https://smassets.pages.dev/assets/1.jpg';
let d2 = 'https://smassets.pages.dev/assets/2.jpg';
let d3 = 'https://smassets.pages.dev/assets/3.jpg';
let d4 = 'https://smassets.pages.dev/assets/4.jpg';
let d5 = 'https://smassets.pages.dev/assets/5.jpg';
let d6 = 'https://smassets.pages.dev/assets/6.jpg';
let d7 = 'https://smassets.pages.dev/assets/7.jpg';
let d8 = 'https://smassets.pages.dev/assets/8.jpg';
let d9 = 'https://smassets.pages.dev/assets/9.jpg';
let d10 = 'https://smassets.pages.dev/assets/10.jpg';
let d11 = 'https://smassets.pages.dev/assets/11.jpg';
let d12 = 'https://smassets.pages.dev/assets/12.jpg';
let d13 = 'https://smassets.pages.dev/assets/13.jpg';
let d14 = 'https://smassets.pages.dev/assets/14.jpg';
let d15 = 'https://smassets.pages.dev/assets/15.jpg';
let d16 = 'https://smassets.pages.dev/assets/16.jpg';
let d17 = 'https://smassets.pages.dev/assets/17.jpg';
let d18 = 'https://smassets.pages.dev/assets/18.jpg';
let d19 = 'https://smassets.pages.dev/assets/19.jpg';
let d20 = 'https://smassets.pages.dev/assets/20.jpg';
let d21 = 'https://smassets.pages.dev/assets/21.jpg';
let d22 = 'https://smassets.pages.dev/assets/22.jpg';
let d23 = 'https://smassets.pages.dev/assets/23.jpg';
let d24 = 'https://smassets.pages.dev/assets/24.jpg';
let d25 = 'https://smassets.pages.dev/assets/25.jpg';
let d26 = 'https://smassets.pages.dev/assets/26.jpg';
let d27 = 'https://smassets.pages.dev/assets/27.jpg';
let d28 = 'https://smassets.pages.dev/assets/28.jpg';
let d29 = 'https://smassets.pages.dev/assets/29.jpg';
let d30 = 'https://smassets.pages.dev/assets/30.jpg';
let d31 = 'https://smassets.pages.dev/assets/31.jpg';
let d32 = 'https://smassets.pages.dev/assets/32.jpg';
let d33 = 'https://smassets.pages.dev/assets/33.jpg';
let d34 = 'https://smassets.pages.dev/assets/34.jpg';


class Carousel extends Component { 

    render() {
        return (

            <div class="boxWrapC">

                <div class="slider">
                    <div class="slide-track2">
                        <div class="slide">
                            <img class="carouselIMG" src={d1} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d2} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d3} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d4} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d5} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d6} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d7} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d8} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d9} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d10} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d11} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d12} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d13} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d14} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d15} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d16} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d17} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d18} alt="d5" />
                        </div>
                    </div>
                </div>

                <div class="slider2">
                    <div class="slide-track3">
                        <div class="slide">
                            <img class="carouselIMG" src={d19} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d20} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d21} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d22} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d23} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d24} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d25} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d26} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d27} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d28} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d29} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d30} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d31} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d32} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d33} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d34} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d8} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d9} alt="d5" />
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Carousel;        